import React from 'react';
import ReactDOM from 'react-dom';
import LoaderProvider from './components/common/Loader';
import ThemeProvider from './config/theme';
import TranslationsProvider from './config/translations';

const App = React.lazy(() => import('./components'));

ReactDOM.render(
  <TranslationsProvider>
    <ThemeProvider>
      <LoaderProvider>
        <App />
      </LoaderProvider>
    </ThemeProvider>
  </TranslationsProvider>,
  document.getElementById('container-app'),
);

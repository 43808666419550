import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import * as translations from '../translations';
import { LANGUAGES_OPTIONS } from '../components/application/LanguageSelect';

export const TranslationsContext = React.createContext({
  languageState: 'sv',
  changeLanguage: () => {
  },
});

const supportedTranslations = { ...translations };

const TranslationsProvider = ({ children }) => {
  const initLanguage = LANGUAGES_OPTIONS[0].value;
  
  const [languageState, setLanguageState] = useState(
    JSON.parse(localStorage.getItem('language')) || initLanguage,
  );
  
  useEffect(() => {
    localStorage.setItem('language', JSON.stringify(languageState));
  }, [languageState]);
  
  const changeLanguage = (language) => setLanguageState(language);
  return (
    <TranslationsContext.Provider
      value={{
        languageState,
        changeLanguage: changeLanguage,
      }}
    >
      <IntlProvider
        locale={languageState}
        messages={supportedTranslations[languageState]}
      >
        {children}
      </IntlProvider>
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;

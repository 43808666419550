import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TranslationsContext } from '../../config/translations';
import { ALMOST_WHITE_COLOR, BLACK_COLOR } from '../../const/color-const';
import ENG from '../../assets/en.svg';
import SWE from '../../assets/sv.svg';

const styles = (theme => ({
  root: {
    '& svg': {
      color: '#8590a5',
    },
  },
  currencySelect: {
    paddingRight: `${theme.spacing(4)}px !important`,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.type === 'light' ? BLACK_COLOR : ALMOST_WHITE_COLOR,
    [theme.breakpoints.up('md')]: {
      color: ALMOST_WHITE_COLOR,
    },
  },
  currencyInput: {
    padding: 0,
  },
}));

export const SE =
  {
    value: 'sv',
    label: 'Svenska',
    img: SWE,
  };

export const GB =
  {
    value: 'en',
    label: 'English',
    img: ENG,
  };

export const LANGUAGES_OPTIONS = [SE, GB];

const LanguageSelect = ({ classes }) => {
  
  return (
    <TranslationsContext.Consumer>
      {({ languageState, changeLanguage }) =>
        <FormControl className={classes.root}>
          <Select
            disableUnderline
            value={languageState}
            classes={{ select: classes.currencySelect }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            onChange={(event) => {
              changeLanguage(event.target.value);
            }}
            input={<Input
              classes={{ input: classes.currencyInput }} />
            }
          >
            {LANGUAGES_OPTIONS.map((option, index) => (
              <MenuItem style={{ fontSize: 14 }} key={index} value={option.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img alt={option.label} src={option.img} style={{ width: 24, marginRight: 4 }} />
                  <span style={{ marginLeft: 4 }}>{option.label}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </TranslationsContext.Consumer>
  );
};

export default withStyles(styles)(LanguageSelect);


export const PRIMARY_COLOR = '#ffc107';
export const SECONDARY_COLOR = '#4E4E4E';
export const PRIMARY_COLOR_LIGHTEN = '#FCD21D';
export const OK_COLOR = '#4CAF50';
export const CANCEL_COLOR = '#af0000';
export const DARK_CUSTOM = '#252b3b';
export const WHITE_COLOR = '#ffffff';
export const FULL_BLACK_COLOR = '#000';
export const ALMOST_WHITE_COLOR = '#f3f3f6';
export const VERY_LIGHT_GREY_COLOR = '#eaedf3';
export const FRESHDESK_GREY = '#EFF1F6';
export const LIGHT_GREY_COLOR = '#aeb6bb';
export const CUSTOM_BORDER = '#5357618c';
export const NAVY_BLUE_COLOR = '#091c43';
export const CUSTOM_GREY_COLOR = '#f1f1f1';
export const GREY_COLOR = '#606e77';
export const DARK_BORDER_COLOR = '#ffffff3b';
export const CUSTOM_GREY = '#d2d2d2';
export const DARK_GREY_COLOR = '#343030';
export const BLACK_COLOR = '#1d222e';
export const PURPLE_DARK = '#36304a';
export const EXPAND_ROW_COLOR = '#ebebeb';
export const SELECTED_ROW = '#d9e7f1';
export const EXPAND_ROW_COLOR_DARK = '#181d26';
export const ALMOST_BLACK_COLOR = '#151921';
export const OUTLINE_COLOR_DARK = '#39425a';
export const YELLOW_COLOR = '#dbab09';
export const DARK_YELLOW_COLOR = '#a28000';
export const GREEN_COLOR = '#38b249';
export const BLUE_COLOR = '#30aadf';
export const ROYAL_BLUE_COLOR = '#0e65d0';
export const DODGER_BLUE = '#1b75ed';
export const NAVBAR_COLOR = '#b2b6bf';
export const RED_COLOR = '#e6492d';
export const DARK_RED_COLOR = '#af0000';
export const LIGHT_GREY = '#d3d3d3';
export const BACKGROUND_WHITE = '#fafafa';
export const BACKGROUND_DARK = '#1F2432';
export const BORDER_LIGHT = '#D3D3D3';
export const BORDER_DARK = '#2b2c2e';
export const BORDER_DARK_TEXT = '#535761';

export const LIGHT_RED_BOX = '#ff7f7f';
export const DARK_RED_BOX = '#b60000';
export const LIGHT_GREEN_BOX = '#8bc18c';
export const DARK_GREEN_BOX = '#689f38';

export const SALE_BACKGROUND_GREEN = '#bbf7d0';
export const RETURN_BACKGROUND_RED = '#fecaca';

export const CELL_LIGHT_COLOR = '#f8f8f8';

export const LIGHT_UPDATED_FIELD = '#eefdee';
export const DARK_UPDATED_FIELD = '#171b2e';

export const DARK_FIELD = '#181c28';

export const PRIMARY_COLOR_SHADES = [
  'rgba(255, 193, 7, 0.2)',
  'rgba(255, 193, 7, 0.5)',
  'rgba(255, 193, 7, 0.8)',
  'rgba(255, 193, 7, 1)',
  'rgba(255, 210, 77, 0.1)',
  'rgba(255, 210, 77, 0.3)',
  'rgba(255, 210, 77, 0.5)',
  'rgba(255, 210, 77, 0.7)',
  'rgba(255, 210, 77, 0.9)',
];

export const SECONDARY_COLOR_SHADES = [
  'rgba(78, 78, 78, 0.1)',
  'rgba(78, 78, 78, 0.2)',
  'rgba(78, 78, 78, 0.4)',
  'rgba(78, 78, 78, 0.6)',
  'rgba(78, 78, 78, 0.8)',
];

export const DASHBOARD_BOX_SHADOW = 'rgb(0 0 0 / 4%) 0 1px 3px 0, rgb(0 0 0 / 4%) 3px 2px 10px 2px';
export const DASHBOARD_BOX_SHADOW_DARK = 'rgb(0 0 0 / 32%) 2px 0px 8px 4px';

export const CUSTOM_BOX_SHADOW = '2px 2px 10px 2px #a2a6aa';
export const SELECTED_TRANSACTION_BOX_SHADOW = '0px 0px 2px 0px #a2a6aa';
export const LOGIN_LIGHT_BOX_SHADOW = 'rgb(149 157 165 / 30%) 0 0 35px 0';
export const LOGIN_DARK_BOX_SHADOW = '0 0 15px 5px #181818';
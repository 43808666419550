import { createTheme, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_LIGHTEN, SECONDARY_COLOR, WHITE_COLOR } from '../const/color-const';

const applicationThemeCreator = ((theme, palletType) =>
  createTheme({
    palette: {
      type: palletType,
      background: {
        default: palletType === 'light' ? WHITE_COLOR : BLACK_COLOR,
      },
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: palletType === 'light' ? SECONDARY_COLOR : WHITE_COLOR,
      },
    },
    typography: {
      fontFamily: 'Gilroy, sans-serif',
      letterSpacing: '0.1px',
      h1: {
        fontSize: '3.32rem',
      },
      h2: {
        fontSize: '3.08rem',
      },
      h3: {
        fontSize: '2.32rem',
      },
      h4: {
        fontSize: '1.92rem',
      },
      h5: {
        fontSize: '1.62rem',
      },
      h6: {
        fontSize: '1.38rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: palletType === 'light' ? '#e7e7e7' : '#303648',
              width: palletType === 'light' ? 16 : 18,
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              backgroundColor: palletType === 'light' ? '#c1c1c1' : '#0f1418',
              borderRadius: 0,
              minHeight: 24,
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: palletType === 'light' ? '#959595' : '#0d1115',
            },
          },
        },
      },
      MuiListItem: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
      MuiListItemSecondaryAction: {
        root: {
          transform: 'translateY(-40%)',
        },
      },
      MuiTableCell: {
        head: {
          padding: `${theme.spacing(1.5)}px !important`,
          lineHeight: '1rem',
          border: `1px solid rgba(0, 0, 0, 0.12)`,
          color: palletType === 'light' ? SECONDARY_COLOR : PRIMARY_COLOR,
          fontWeight: 'bold',
        },
        body: {
          border: `1px solid rgba(0, 0, 0, 0.12)`,
          minWidth: 120,
          maxWidth: 300,
        },
        alignLeft: {
          [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: theme.spacing(5),
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: theme.spacing(1),
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiInputLabel: {
        formControl: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        },
      },
      MuiLinearProgress: {
        root: {
          borderRadius: theme.spacing(2),
          height: theme.spacing(1),
        },
      },
      MuiButton: {
        containedPrimary: {
          '&:not($disabled):before': {
            backgroundColor: PRIMARY_COLOR_LIGHTEN,
          },
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginRight: theme.spacing(2),
          marginLeft: theme.spacing(2),
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
        },
      },
    },
  }));

export const ThemeContext = React.createContext({
  darkState: false,
  toggleTheme: () => {
  },
});

const ThemeProvider = ({ children }) => {
    const initialValue = false;
    
    const [darkState, setDarkState] = useState(() => {
      try {
        const item = localStorage.getItem('darkState');
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        return initialValue;
      }
    });
    
    const palletType = darkState ? 'dark' : 'light';
    const toggleTheme = () => {
      const newValue = !darkState;
      setDarkState(newValue);
      localStorage.setItem('darkState', JSON.stringify(newValue));
    };
    
    return (
      <MuiThemeProvider theme={createTheme()}>
        <ThemeContext.Provider
          value={{
            darkState,
            toggleTheme: toggleTheme,
          }}
        >
          <MuiThemeProvider theme={(theme) => applicationThemeCreator(theme, palletType)}>
            <CssBaseline />
            {children}
          </MuiThemeProvider>
        </ThemeContext.Provider>
      </MuiThemeProvider>
    );
  }
;

export default ThemeProvider;

import { createStyles, withStyles } from '@material-ui/core/styles';
import React, { Suspense } from 'react';
import EskassaLogo from '../../assets/eskassa.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = createStyles(theme => ({
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
    boxShadow: theme.shadows[4],
  },
  progress: {
    position: 'fixed',
    width: '100%',
    bottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Loader = ({ classes }) => (
  <>
    <img
      className={classes.logo}
      src={EskassaLogo}
      alt="Eskassa Logo"
    />
    
    <div className={classes.progress}>
      <CircularProgress
        variant="indeterminate"
        color="secondary"
      />
    </div>
  </>
);

const StyledLoader = withStyles(styles, { name: 'Loader' })(Loader);

const LoaderProvider = ({ children }) => (
  <Suspense fallback={<StyledLoader />}>
    {children}
  </Suspense>
);

export default LoaderProvider;
